/*!
 * Moonkake v5.0.0
 *
 * https://github.com/detectiveshelby/moonkake
 */

$(function () {

  /* VAR
  -------------------------------------------------- */

  var $d = $(document);
  var $w = $(window);
  var $h = $('html');
  var $b = $('body');

  /* UTILS
  -------------------------------------------------- */

  var $mk = {
    scrollTo: function ($object, offset, callback) {
      $('html, body').stop().animate({
        scrollTop: $object.offset().top - ((typeof (offset) == 'number') ? offset : 0)
      }, 500, function () {
        if ($(this)[0].nodeName == 'HTML') {
          if (typeof (callback) == 'function') {
            callback();
          }
        }
      });
    }
  };

  /* SCROLL
  -------------------------------------------------- */

  $('.js-scroll').on('click', function () {
    var href = $(this).attr('href');

    $mk.scrollTo($(href), 0);

    return false;
  });

  /* POPUP
  -------------------------------------------------- */

  $('.js-popup').magnificPopup({
    type: 'inline',
    midClick: true,
    closeBtnInside: true,
    removalDelay: 300,
    mainClass: 'mfp-fade',
    fixedContentPos: false
  });

  $('.js-image').magnificPopup({
    type: 'image',
    closeOnContentClick: true,
    removalDelay: 300,
    mainClass: 'mfp-fade mfp-img-mobile',
    image: {
      verticalFit: true
    }
  });

  $('.js-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Загрузка изображения #%curr%...',
    mainClass: 'mfp-fade mfp-img-mobile',
    removalDelay: 300,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1],
      tCounter: '<span class="mfp-counter">%curr% из %total%</span>'
    },
    image: {
      tError: '<a href="%url%">Изображение #%curr%</a> не может быть загружено.'
    }
  });

  /* TABS
  -------------------------------------------------- */

  var $tabs = {
    opts: {
      root: '.tabs:not(.tabs-nojs)',
      title: '.tab-title',
      content: '.tab-content',
      item: '.tab-item',
      active: '-active'
    },

    changeTab: function ($root, index) {
      var opt = this.opts;

      $root.find(opt.title + ' ' + opt.item).eq(index).addClass(opt.active).siblings(opt.item).removeClass(opt.active);
      $root.find(opt.content + ' ' + opt.item).eq(index).addClass(opt.active).siblings(opt.item).removeClass(opt.active);

      $(document).trigger('tab_change', {
        root: $root,
        index: index
      });
    },

    init: function () {
      var $this = this;
      var opt = $this.opts;

      $(opt.title).on('click', opt.item, function () {
        var _ = $(this);
        var $root = _.closest(opt.root);
        var index = _.index();

        $this.changeTab($root, index);
      });
    }
  };

  $tabs.init();

  $d.on('tab_change', function(event, response) {
    if ($(response.root).hasClass('tabs-services')) {
      if ($d.width() <= 450) {
        $mk.scrollTo($(response.root).find('.tab-content'), 0);
      }
    }
  });

  $('.tabs-build').on('click', '.tab-title .tab-item', function() {
    if ($d.width() <= 550) {
      $mk.scrollTo($('.tabs-build').find('.tab-section'), 20);
    }
  });

  var tabsBuildTimeout;
  var tabsBuildInterval;

  function tabsBuildAutochange(index) {
    var index = index || 0;

    tabsBuildInterval = setInterval(function() {
      index += 1;
      index = (index > $('.tabs-build .tab-title .tab-item').length - 1) ? 0 : index;

      $tabs.changeTab($('.tabs-build'), index);
    }, 2000);
  }

  $('.tabs-build .tab-title .tab-item').on('click', function() {
    var index = $(this).index();

    clearInterval(tabsBuildInterval);
    clearTimeout(tabsBuildTimeout);

    tabsBuildTimeout = setTimeout(function() {
      tabsBuildAutochange(index);
    }, 10000);
  });

  tabsBuildAutochange();

  /* CAROUSEL
  -------------------------------------------------- */

  $('.carousel-hero').slick({
    accessibility: false,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
    fade: true,
    slidesToShow: 1,
    pauseOnFocus: false,
    pauseOnHover: false
  });

  $('.carousel-brands').slick({
    slidesToScroll: 3,
    accessibility: false,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: false,
    slidesToShow: 6,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.carousel-gallery').slick({
    accessibility: false,
    arrows: true,
    dots: false,
    slidesToShow: 1
  });

  /* OBJECTS
  -------------------------------------------------- */

  function randomInteger(min, max) {
    var rand = min - 0.5 + Math.random() * (max - min + 1)
    rand = Math.round(rand);
    return rand;
  }

  function buildObject(tpl, object) {
    return tpl
      .replace(/{\$title}/gim, object.title)
      .replace(/{\$address}/gim, object.address)
      .replace(/{\$image}/gim, object.image)
      .replace(/{\$link}/gim, object.link);
  }

  function checkExistObjects() {
    var number = randomInteger(0, objects.length - 1);

    var exists = $(jsObjects).map(function() {
      return Number($(this).attr('data-key'));
    }).get();

    if ($.inArray(number, exists) > -1) {
      return checkExistObjects();
    } else {
      return number;
    }
  }

  $('.carousel-objects').slick({
    accessibility: false,
    arrows: true,
    dots: false,
    slidesToShow: 1
  });

  if (typeof objects !== 'undefined' && objects.length > 8 && window.innerWidth >= 1000) {

    var jsObjects = '.carousel-objects .carousel-item:not(.slick-cloned) .js-objects .list-item';
    var objectsFiltered = false;

    $('.carousel-objects').on('afterChange', function(event, slick, currentSlide, nextSlide){
      if (currentSlide > 0 && !objectsFiltered) {
        $('.carousel-objects').slick('slickFilter', ':not(:first)');

        objectsFiltered = true;

        clearInterval(objectsInterval);
      }
    });

    $.each(objects, function(key, object) {
      if (key <= 7) {

        var element = buildObject(objectTpl, object);

        $('.js-objects').append($('<li>', {
            'class': 'list-item',
            'data-key': key
          })
          .html('<div class="cover"><div class="cover-stage"><div class="cover-front">' + element +'</div><div class="cover-back"></div></div>')
        );

      }
    });

    var objectsInterval = setInterval(function() {
      var index = randomInteger(0, 7);
      var indexObject = checkExistObjects();

      if ($(jsObjects).find('.cover').filter('.-active').length) {

        $(jsObjects).find('.cover').filter('.-active').each(function() {
          var _ = $(this);
          var front = _.find('.cover-front');
          var back = _.find('.cover-back');

          front.html(back.html());
          back.html('');

          _.removeClass('-active');
        });

      }

      $(jsObjects).eq(index)
        .find('.cover-back').html(buildObject(objectTpl, objects[indexObject]))
        .closest('.cover').addClass('-active')
        .closest('.list-item').attr('data-key', indexObject);

    }, 3000);

  } else {
    $('.carousel-objects').slick('slickFilter', ':not(:first)');
  }

  /* NAVIGATION
  -------------------------------------------------- */

  var $toggler = {
    options: {
      toggler: 'data-toggler',
      target: 'data-target',
      active: '-active'
    },

    init: function ($options) {
      var $this = this;

      if (typeof $options == 'object') {
        $this.options = $options;
      }

      $('[' + $this.options.toggler + ']').on('click', function () {
        var _ = $(this);
        var target = _.attr($this.options.target);

        _.toggleClass($this.options.active);
        $('#' + target).toggleClass($this.options.active);

        return false;
      });

      $d.on('click', function () {
        if ($('[' + $this.options.target + ']').hasClass($this.options.active)) {
          $('[' + $this.options.toggler + ']').removeClass($this.options.active);

          $('[' + $this.options.target + ']').each(function () {
            var target = $(this).attr($this.options.target);
            $('#' + target).removeClass($this.options.active);
          });
        }
      });

      $('[' + $this.options.target + ']').on('click', function (event) {
        event.stopPropagation();
      });

    }
  }

  $toggler.init();

});
